import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'Optimism Grows When Leaders Prioritize Inclusion',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/ypfir4YC3x8',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Where local political, community, and philanthropic leaders openly
      prioritize racial diversity and inclusion, there is more hope and optimism
      in their community’s future among residents.
    </p>
    <p>
      Residents across the South consistently cited political leadership and
      effective government as critical avenues for creating systemic change to
      issues of race and class. Political leaders are using their convening
      power to prompt communitywide discussions about race and healing. In the
      places where leaders openly embraced the concepts of racial equity and
      diversity, there is more awareness and openness to the concepts across the
      community.
    </p>
    <p>
      On the flip side, many residents expressed frustration with a lack of
      transparency from their local governments, as well as the lack of concrete
      outcomes to show for a variety of discussions and equity planning
      processes, such as more community development investment, affordable
      housing, and equitable transportation planning. Racial disparities across
      areas like housing and education further contribute to the lack of trust,
      particularly among communities of color.
    </p>
    <p>
      Many black interviewees also acknowledged the legacy of fear of law
      enforcement, rooted in a deeply painful history, which has been replicated
      or reinforced by police violence in recent years. More transparency,
      engagement from political leadership and policies like body-worn cameras
      were discussed as ways to improve police-community race relations.
    </p>

    <Quote
      quote="I was at a meeting with some person a few years older than me, and they said, ‘We’ve been meeting forever,’ and we have. We’re gonna always have to meet because time brings about change. The population is changing. We need new leadership who understand the burden that you have been bearing. And you’ve got to communicate that to them and then get a response."
      person="Black male, 50"
      title="Richmond"
    />
  </Modal>
);
